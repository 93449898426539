const baseURL = 'http://172.16.50.238:7020/manage'
// 获取列表接口
const getModelListURL = `${API_CONFIG.butlerBaseURL}activiti/models/modelList`
// 新增数据接口
const createURL = `${API_CONFIG.butlerBaseURL}activiti/models/create`
// 删除数据接口
const deleteURL = `${API_CONFIG.butlerBaseURL}activiti/models/delete/`
// 删除数据接口
const batchDeleteURL = `${API_CONFIG.butlerBaseURL}activiti/models/deleteBatch`
// 编辑模型
const editURL = `${API_CONFIG.butlerBaseURL}activiti/models/edit/`
// 发布模型
const deploymentURL = `${API_CONFIG.butlerBaseURL}activiti/models/deployment/`
// 画布请求接口
const drawURL = `${API_CONFIG.butlerBaseURL}activiti/modeler.html?modelId=`


export {
    getModelListURL,
    createURL,
    deleteURL,
    batchDeleteURL,
    editURL,
    deploymentURL,
    drawURL
}