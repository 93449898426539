<template>
  <div class="ModelList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="创建流程模型" permission="add" @click="create" />
      </template>
      <template #searchSlot>
        <v-input label="模型名称" v-model="searchParams.keyWord" placeholder="请输入" />
        <v-datepicker label="创建时间" :maxDate="maxDate" type="rangedatetimer" :startTime.sync="searchParams.createTime_begin" :endTime.sync="searchParams.createTime_end" />
      </template>
      <template #operateSlot="scope">
        <!-- <v-button text="发布模型" type="text" permission="update" @click="deployment(scope.row)" /> -->
        <v-button text="设计流程" type="text" permission="update" @click="updatelc(scope.row)" />
        <v-button text="删除" type="text" permission="delete" @click="handleDelete(scope.row)" />
      </template>
      <el-dialog title="创建模型"
        :visible.sync="createObj.visible" >
        <el-form class="audit-from-wrapper"  >
          <el-form-item>
            <v-input label="模型名称" v-model="createObj.name" placeholder="请输入" />
          </el-form-item>
          <el-form-item >
            <v-input label="模型标识" v-model="createObj.key" placeholder="请输入" />
          </el-form-item>
          <el-form-item >
            <v-input label="模型描述" v-model="createObj.description" placeholder="请输入" />
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="createObj.visible = false">取 消</el-button>
          <el-button type="primary" @click="createObjOk">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="设计模型"
        v-if="updateObj.visible"
        :visible.sync="updateObj.visible" width="90%"
        :before-close="handleClose">
        <iframe
        :src="iframUrl"
        frameborder="0"
        width="100%"
        height="800px"
        scrolling="auto"
        style="background-color: #fff;"></iframe>
      </el-dialog>
    </list>
  </div>
</template>

<script>
import { getModelListURL, deleteURL, batchDeleteURL, createURL, editURL, deploymentURL, drawURL } from './api'
// import { formStatusOps, formStatusMap } from './map'

export default {
  name: 'ModelList',
  data () {
    return {
      token: this.$store.state.token,
      baseURL: API_CONFIG.controlBaseURL,
      pathname: window.location.pathname,
      publicPath: process.env.BASE_URL,
      searchUrl: getModelListURL,
      maxDate: new Date(),
      iframUrl: '',
      createObj: {
          visible: false,
          confirmLoading: false,
          name: '',
          key: '',
          description: ''
      },
      searchParams: {
        keyWord: '',
        createTime_begin: '',
        createTime_end: ''
      },
      updateObj: {
          visible: false,
          confirmLoading: false
      },
      headers: [
        {
          prop: 'id',
          label: '模型ID'
        },
        {
          prop: 'name',
          label: '模型名称'
        },
        {
          prop: 'key',
          label: '模型标识'
        },
        {
          prop: 'metaInfo',
          label: '模型描述'
        },
        {
          prop: 'createTime',
          label: '创建时间'
        },
        {
          prop: 'lastUpdateTime',
          label: '最后更新时间'
        }
      ]
    }
  },
  computed: {
    visible () {
      const userType = this.$store.state.userInfo.userType
      return userType === '106'
    }
  },
  mounted() {
    window.getModelListComponent = () => {
      return this
    }
  },
  methods: {
    create () {
      if (this.visible) {
        this.createObj.visible = true
      } else {
        this.$message.error('用户无操作权限!')
      }
    },
    async createObjOk () {
      this.createObj.confirmLoading = true
      
      let { status, data} = await this.$axios.post(createURL,null, {params: this.createObj})
      // this.iframUrl = `${createURL}?name=${this.createObj.name || ''}&key=${this.createObj.key || ''}&description=${this.createObj.description || ''}`
      if(status === 100) {
        this.updateObj.visible = true
        this.iframUrl = `static/activiti/modeler.html?modelId=${data}`
      }
      this.createObj.visible = false
      this.createObj.confirmLoading = false
      this.$refs.list.searchData()
    },
    async updatelc (row) {
      this.createObj.confirmLoading = true
      // let { status, data} = await this.$axios.get(`${editURL}${row.id}`)
      // if(status === 100) {
      //   this.iframUrl = data
      //   this.updateObj.visible = true
      // }
      console.log(this.publicPath)
      this.iframUrl = `static/activiti/modeler.html?modelId=${row.id}`
      this.updateObj.visible = true
      this.createObj.confirmLoading = false
    },
    edit (row) {
      if (this.visible) {
        this.$router.push({
          name: 'LeaveForm',
          query: {
            id: row.id
          }
        })
      } else {
        this.$message.error('用户无操作权限!')
      }
    },
    async handleDelete (row) {
        const result = await this.$confirm('确认删除？', {
            title: '提示'
        })
        if(result) {
            var that = this
            that.$axios.get(deleteURL + row.id).then((res) => {
                if (res.status === 100) {
                    that.$message.success(res.data)
                    that.$refs.list.searchData()
                } else {
                    that.$message.warning(res.data)
                }
            })
        }
       
    },
    async deployment (row) {
        const result = await this.$confirm(`确认部署流程:${row.name}`, {
            title: '确认部署流程？'
        })
        if(result) {
            var that = this;
            that.$axios.get(deploymentURL + row.id).then((res) => {
                if (res.status === 100) {
                    that.$message.success(res.data)
                } else {
                    // that.$message.error(res.msg)
                }
                that.$refs.list.searchData()
            })
        }
    },
    async handleClose (done) {
      const result = await this.$confirm('流程设计还没有保存，是否返回列表？')
      if (result) {
        done()
      }
    }

  }
}
</script>
